import {
  CLOSE_MODAL,
  OPEN_MODAL,
  RELOAD_COMPONENT,
  SET_CURRENT_ROW,
  SET_MODAL_WIDTH,
} from "./../actions/types";

const initialState = {
  currentRow: null,
  isModalOpen: false,
  modalWidth: undefined,
  modalType: null,
  modalData: null,
  reload: false,
};

export const uiReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CURRENT_ROW:
      return { ...state, currentRow: action.payload };
    case SET_MODAL_WIDTH:
      return { ...state, modalWidth: action.payload };
    case OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true,
        modalType: action.payload,
        modalData: action.data,
      };
    case CLOSE_MODAL:
      return { ...state, isModalOpen: false, modalType: null };
    case RELOAD_COMPONENT:
      return { ...state, reload: !state.reload };
    default:
      return state;
  }
};
