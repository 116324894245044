import React from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import { closeModal, reloadComponent } from "../../actions/uiActions";
import { ModalType } from "../../models/Modal";
import FileUploader from "../FileUploader/FileUploader";
import { useAppDispatch } from "../../hooks/reactRedux";
import { GeneralState } from "../../models/State";

const SMSModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { modalType, isModalOpen, modalWidth } = useSelector<GeneralState, any>((state: any) => state.ui);

  const handleOnClose = () => {
    if (modalType === ModalType.fileUploaderModal) {
      setTimeout(() => dispatch(reloadComponent()), 1000);
    }
    dispatch(closeModal());
  };

  const showModalContent = () => {
    switch (modalType) {
      case ModalType.fileUploaderModal:
        return <FileUploader />;
      default:
        return null;
    }
  };

  return (
    (<Modal open={isModalOpen} onCancel={handleOnClose} footer={null} width={modalWidth} destroyOnClose={true}>
      {showModalContent()}
    </Modal>)
  );
};

export default SMSModal;
