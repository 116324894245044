import React from "react";
import { Button, Collapse, Tag } from "antd";
import { capitalize, getColors } from "../../utils/Utils";
import "./SMSDetailsPageMobile.less";
import { openModal } from "../../actions/uiActions";
import { UploadOutlined } from "@ant-design/icons";
import { ModalType } from "../../models/Modal";
import { Document } from "../../api/sellmyshares-api";
import moment from "moment";
import { useAppDispatch } from "../../hooks/reactRedux";

const { Panel } = Collapse;
interface SMSDetailsPageMobileProps {
  data: any;
  documents: Document[];
}

const SMSDetailsPageMobile: React.FC<SMSDetailsPageMobileProps> = ({
  data,
  documents,
}) => {
  const dispatch = useAppDispatch();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        margin: "1rem",
      }}
    >
      <div className="SMSDetailsPageMobile">
        <div className="sale-details-header">
          <img
            style={{ width: 45 }}
            alt="SMS small Logo"
            src={process.env.PUBLIC_URL + "/images/sms-small-logo.png"}
          />
          <h1 className="sms-title">Share Sale Details</h1>
          <div className="sms-tag">
            <Tag
              color={getColors(data.status ?? "")}
              style={{
                color: "#000000",
                borderRadius: 16,
                padding: "2px 15px",
                fontWeight: "bold",
              }}
            >
              {data.status ? data.status.toUpperCase() : ""}
            </Tag>
          </div>
        </div>
        <div className="sale-details-container">
          <div className="individual-info-card">
            <p className="info-title">SALE NO/ID</p>
            <p className="info-description">{data.saleId}</p>
          </div>
          <div className="individual-info-card">
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <p className="info-title">IN TRUST</p>{" "}
            </span>
            <p className="info-description">{data.isTrust ? "Yes" : "No"}</p>
          </div>
          <div className="individual-info-card">
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <p className="info-title">POA</p>{" "}
            </span>
            <p className="info-description">
              {data.isPoa ? <span>Yes</span> : <span>No</span>}
            </p>
          </div>
        </div>
        <div className="sale-details-container">
          <div className="individual-info-card">
            <p className="info-title">DATE LODGED</p>
            <p className="info-description">{data.dateLodged.split("T")[0]}</p>
          </div>
          <div className="individual-info-card">
            <p className="info-title">SHAREHOLDER TYPE</p>
            <p className="info-description">
              {data.shareholderType
                ? data.shareholderType.split("(")[0]
                : data.shareholderType}
            </p>
          </div>
        </div>

        <h2 style={{ marginTop: "1rem", fontSize: 20, fontWeight: 500 }}>
          Persons
        </h2>

        <Collapse style={{ borderRadius: 0 }} activeKey={Array.from({ length: data?.naturalPersons?.length }, (_, index) => index)}>
          {data?.naturalPersons?.map((person: any, index: number) => (
            <Panel style={{ borderRadius: 0 }} showArrow={false} key={index} header={<span style={{ fontSize: 15, fontWeight: 800, paddingLeft: "4px" }}> {person.name} </span>}>
              <div className="ant-collapse-content-box">
                <div className="panel-container ">
                  <span>Relationship: </span>{" "}
                  <span>{person.relationshipType}</span>
                </div>
                <div className="panel-container">
                  <span>T&Cs: </span>{" "}
                  <span>{person.tncStatus ? "✅ Accepted" : "-"}</span>
                </div>
                <div className="panel-container">
                  <span>ID Verified: </span>{" "}
                  <span>
                    {person.kycStatus?.includes("PASS") ? "✅ Yes" : "-"}
                  </span>
                </div>
              </div>
            </Panel>
          ))}
        </Collapse>

        <h2 style={{ marginTop: "1.5rem", fontSize: 20, fontWeight: 500 }}>
          Share Holdings <span style={{ fontWeight: 100 }}>(Parcels)</span>
        </h2>

        <Collapse style={{ borderRadius: 0 }} activeKey={Array.from({ length: data?.shareParcels?.length }, (_, index) => index)}>
          {data?.shareParcels?.map((parcel: any, index: number) => (
            <Panel style={{ borderRadius: 0 }} showArrow={false} key={index} header={<span style={{ fontSize: 15, fontWeight: 800, paddingLeft: "4px" }}> {parcel.companyCode} </span>}>
              <div className="ant-collapse-content-box">
                <div className="panel-container">
                  <span>Number of Shares: </span>{" "}
                  <span>{parcel.howMuch}</span>
                </div>
                <div className="panel-container">
                  <span>SRN Supplied: </span>{" "}
                  <span>{parcel.srnSupplied ? "✅ Yes" : "-"}</span>
                </div>
                <div className="panel-container">
                  <span>Sale Status: </span>{" "}
                  <span>
                    {!parcel.saleStatus
                      ? "-"
                      : `${parcel.saleStatus === "Sold" ? "✅" : ""} ${parcel.saleStatus
                      }`}
                  </span>
                </div>
              </div>
            </Panel>
          ))}
        </Collapse>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <h2 style={{ marginTop: "1rem", fontSize: 20, fontWeight: 500 }}>
            Uploaded Documents
          </h2>
          <Button
            onClick={() => dispatch(openModal(ModalType.fileUploaderModal))}
            type="primary"
            icon={<UploadOutlined />}
            style={{ position: "absolute", right: 0, borderRadius: 0 }}
          >
            Upload
          </Button>
        </div>
        <Collapse style={{ borderRadius: 0 }} activeKey={Array.from({ length: documents?.length }, (_, index) => index)}>
          {documents?.map((doc: any, index: number) => (
            <Panel style={{ borderRadius: 0 }} showArrow={false} key={index} header={<span style={{ fontSize: 15, fontWeight: 800, paddingLeft: "4px" }}> {doc.filename} </span>}>
              <div className="ant-collapse-content-box">
                <div className="panel-container">
                  <span>Date Uploaded: </span>{" "}
                  <span>{moment(doc.creationDate).format("DD/MM/yyyy")}</span>
                </div>
                <div className="panel-container">
                  <span>Doc Type: </span>
                  <span>
                    <Tag style={{ margin: 0 }}>
                      {capitalize(
                        (doc.documentType as string).replaceAll("_", " ")
                      )}
                    </Tag>
                  </span>
                </div>
              </div>
            </Panel>
          ))}
        </Collapse>
      </div>
      <img
        style={{ width: 180, margin: "auto" }}
        alt="SMS Logo"
        src={process.env.PUBLIC_URL + "/images/sellmyshares-logo.svg"}
      />
    </div>
  );
};

export default SMSDetailsPageMobile;
