import moment from "moment";
import { format } from "date-fns";

export const formatDate = (date: string | undefined, formated: string) => {
  return date ? format(new Date(Date.parse(date)), formated) : "";
};

export const getInitials = (name: string | undefined) => {
  if (name) {
    const nameArr = name.split(" ");
    return `${nameArr[0][0]} ${nameArr[nameArr.length - 1][0]}`;
  } else {
    return `User`;
  }
};

export const capitalize = (text: string) => {
  return text
    ? text.charAt(0).toUpperCase() + text.toLocaleLowerCase().slice(1)
    : "";
};

export const capitalizeEveryWord = (text: string) => {
  return text
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getColors = (status: string) => {
  switch (status.toLowerCase()) {
    case "new":
      return "geekblue";
    case "precessing":
      return "yellow";
    case "sold":
      return "green";
    case "cancelled":
      return "red";
    case "Int. Shares":
      return "#72bcd4";
    case "HIN":
      return "#7f00ff";
    default:
      return "";
  }
};

export function greeting() {
  const hour = moment().hour();

  if (hour > 16) {
    return "Good evening";
  }

  if (hour > 11) {
    return "Good afternoon";
  }

  return "Good morning";
}
