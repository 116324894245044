import { httpBaseUrl } from "./config/config";
import { PublicSaleDetailsApi, PublicSaleDocumentsApi, Document, DocumentType } from "./sellmyshares-api";

const saleOrderApi = new PublicSaleDetailsApi({
  basePath: httpBaseUrl,
} as any);

const publicSaleDocumentsApi = new PublicSaleDocumentsApi({
  basePath: httpBaseUrl,
} as any);

const getSaleStatus = async (saleId: string, contactLastName: string) => {
  return new Promise((resolve, reject) => {
    saleOrderApi
      .getSaleStatus(saleId, contactLastName)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
const listDocuments = async (saleId: string, contactLastName: string): Promise<Document[]> => {
  return new Promise((resolve, reject) => {
    publicSaleDocumentsApi
      .listDocuments(saleId, contactLastName)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const uploadDocument = async (saleId: string, contactLastName: string, fileName: string, documentType: DocumentType, body?: any, options?: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    publicSaleDocumentsApi
      .uploadDocument(saleId, contactLastName, fileName, documentType, body, options)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const api = {
  getSaleStatus,
  listDocuments,
  uploadDocument,
};
