import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "./Loader.less";

interface LoaderProps {
  text?: string;
}

export const Loader: React.FC<LoaderProps> = ({ text }) => {
  return (
    <div className="loader">
      <LoadingOutlined style={{ fontSize: 40 }} spin />
      <p style={{ marginTop: "1rem" }}>{text}</p>
    </div>
  );
};
