import {
  FETCH_DATA_PENDING,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_ERROR,
} from "../actions/types";

const initialState = {
  isAuth: false,
  data: [],
  isLoading: false,
  error: null,
};

export const fetchDataReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case FETCH_DATA_PENDING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.data,
        isAuth: true,
        isLoading: false,
      };
    case FETCH_DATA_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};
