import React, { useState } from "react";
import { CheckCircleFilled, CloseCircleFilled, UploadOutlined } from "@ant-design/icons";
import { Button, Upload, Select } from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import type { UploadProps } from "antd";
import { DocumentType } from "../../api/sellmyshares-api";
import { RcFile } from "antd/lib/upload";
import { useQuery } from "../../hooks/useQuery";
import useWindowDimensions from "../../hooks/useWindowsDimensions";
import "./FileUploader.less";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { httpBaseUrl } from "../../api/config/config";

const FileUploader: React.FC = () => {
  const query = useQuery();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [error, setError] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [documentType, setDocumentType] = useState<DocumentType>(DocumentType.CertifiedWill);
  const [uploading, setUploading] = useState(false);
  const { width } = useWindowDimensions();

  const handleDocumentType = (value: DocumentType) => {
    setDocumentType(value);
  };

  const uploadDocument = (saleId: string, contactLastName: string, fileName: string, documentType: DocumentType, body: RcFile): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      const uploadDocumentApi: string = `${httpBaseUrl}/v1/public/sale-details/${saleId}/documents`;
      const config: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        params: {
          contactLastName: contactLastName,
          filename: fileName,
          documentType: documentType
        }
      };

      try {
        const response: AxiosResponse<any> = await axios.post(uploadDocumentApi, body, config);
        resolve(response.data);
      }
      catch (error) {
        reject(error);
      }
    });
  }

  const handleUpload = async () => {
    const saleId = query.get("saleId") || "";
    const contactLastName = query.get("contactLastName") || "";
    const file = fileList[0] as RcFile;

    setUploading(true);

    try {
      await uploadDocument(saleId, contactLastName, fileList[0].name, documentType, file);

      setFileList([]);
      setUploaded(true);
    }
    catch (error: any) {
      setError(error);
    }
    finally {
      setUploading(false);
    }
  };

  const options = [
    {
      value: DocumentType.CertifiedWill,
      label: "Certified Will",
    },
    {
      value: DocumentType.CertifiedDeathCertificate,
      label: "Certified Death Certificate",
    },
    {
      value: DocumentType.CertifiedProbate,
      label: "Certified Probate",
    },
    {
      value: DocumentType.Other,
      label: "Other",
    },
  ];

  const props: UploadProps = {
    fileList,
    listType: "picture",
    maxCount: 1,
    className: "upload-list-inline",
    accept: "image/*,.pdf,.doc,.docx",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
  };

  return (
    <div className="FileUploader">
      {!uploaded && !error ? (
        <>
          <h2 className="file-uploader-title">{fileList.length === 0 ? "Select the type of document you are uploading" : "Upload the document"}</h2>
          {fileList.length === 0 ? <Select onChange={handleDocumentType} options={options} defaultValue={documentType} style={{ minWidth: width < 500 ? 220 : 250 }} /> : null}
          <Upload {...props} fileList={fileList}>
            {fileList.length === 0 ? <Button icon={<UploadOutlined />}>Upload</Button> : null}
          </Upload>
          <Button type="primary" onClick={handleUpload} disabled={fileList.length === 0} loading={uploading} style={{ marginTop: 16 }}>
            {uploading ? "Uploading" : "Start Upload"}
          </Button>
        </>
      ) : uploaded && !error ? (
        <div className="upload-status-container">
          <h1>Upload Successfully!</h1>
          <CheckCircleFilled style={{ fontSize: 50, color: "#2cd419" }} />
        </div>
      ) : (
        <div className="upload-status-container">
          <h1>Upload Failed</h1>
          <CloseCircleFilled style={{ fontSize: 50, color: "#d41919" }} />
        </div>
      )}
    </div>
  );
};

export default FileUploader;
