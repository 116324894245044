import React from "react";
import "./index.less";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from 'react-dom/client';
import AntConfigProvider from "./AntConfigProvider";

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Router>
    <Provider store={store}>
      <AntConfigProvider>
        <App />
      </AntConfigProvider>
    </Provider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
