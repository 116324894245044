/* tslint:disable */
/* eslint-disable */
/**
 * Sell My Shares Api Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'filename'?: string;
    /**
     * 
     * @type {DocumentType}
     * @memberof Document
     */
    'documentType'?: DocumentType;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'creationDate'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentType = {
    CertifiedIdentityDocuments: 'CERTIFIED_IDENTITY_DOCUMENTS',
    CertifiedNameChange: 'CERTIFIED_NAME_CHANGE',
    CertifiedPowerOfAttorney: 'CERTIFIED_POWER_OF_ATTORNEY',
    CertifiedIdOfPersonsUnderPoaOrEpoa: 'CERTIFIED_ID_OF_PERSONS_UNDER_POA_OR_EPOA',
    CertifiedTrustDeed: 'CERTIFIED_TRUST_DEED',
    CertifiedWill: 'CERTIFIED_WILL',
    CertifiedDeathCertificate: 'CERTIFIED_DEATH_CERTIFICATE',
    CertifiedProbate: 'CERTIFIED_PROBATE',
    CertifiedRecentProofOfAddress: 'CERTIFIED_RECENT_PROOF_OF_ADDRESS',
    CertifiedRecentBankStatement: 'CERTIFIED_RECENT_BANK_STATEMENT',
    InternationalBankingForm: 'INTERNATIONAL_BANKING_FORM',
    Other: 'OTHER'
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];


/**
 * 
 * @export
 * @interface NaturalPerson
 */
export interface NaturalPerson {
    /**
     * 
     * @type {string}
     * @memberof NaturalPerson
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NaturalPerson
     */
    'tncStatus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NaturalPerson
     */
    'kycStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof NaturalPerson
     */
    'relationshipType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NaturalPerson
     */
    'underPOA'?: boolean;
}
/**
 * 
 * @export
 * @interface SaleDetails
 */
export interface SaleDetails {
    /**
     * 
     * @type {string}
     * @memberof SaleDetails
     */
    'saleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleDetails
     */
    'dateLodged'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleDetails
     */
    'contactName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleDetails
     */
    'shareholderType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SaleDetails
     */
    'isTrust'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaleDetails
     */
    'isPoa'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaleDetails
     */
    'settlement'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleDetails
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleDetails
     */
    'currentTimeSydney'?: string;
    /**
     * 
     * @type {Array<ShareParcel>}
     * @memberof SaleDetails
     */
    'shareParcels'?: Array<ShareParcel>;
    /**
     * 
     * @type {Array<NaturalPerson>}
     * @memberof SaleDetails
     */
    'naturalPersons'?: Array<NaturalPerson>;
}
/**
 * 
 * @export
 * @interface ShareParcel
 */
export interface ShareParcel {
    /**
     * 
     * @type {string}
     * @memberof ShareParcel
     */
    'shareholderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareParcel
     */
    'dateLodged'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareParcel
     */
    'companyCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareParcel
     */
    'howMuch'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShareParcel
     */
    'units'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShareParcel
     */
    'srnSupplied'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShareParcel
     */
    'saleDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareParcel
     */
    'status'?: string;
}

/**
 * InternalJobsApi - axios parameter creator
 * @export
 */
export const InternalJobsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectSaleMetrics: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/internal/jobs/collect-sale-metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalJobsApi - functional programming interface
 * @export
 */
export const InternalJobsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalJobsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectSaleMetrics(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectSaleMetrics(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalJobsApi - factory interface
 * @export
 */
export const InternalJobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalJobsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectSaleMetrics(options?: any): AxiosPromise<void> {
            return localVarFp.collectSaleMetrics(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalJobsApi - object-oriented interface
 * @export
 * @class InternalJobsApi
 * @extends {BaseAPI}
 */
export class InternalJobsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalJobsApi
     */
    public collectSaleMetrics(options?: AxiosRequestConfig) {
        return InternalJobsApiFp(this.configuration).collectSaleMetrics(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicSaleDetailsApi - axios parameter creator
 * @export
 */
export const PublicSaleDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} saleId 
         * @param {string} contactLastName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleStatus: async (saleId: string, contactLastName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('getSaleStatus', 'saleId', saleId)
            // verify required parameter 'contactLastName' is not null or undefined
            assertParamExists('getSaleStatus', 'contactLastName', contactLastName)
            const localVarPath = `/v1/public/sale-details/{saleId}/status`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contactLastName !== undefined) {
                localVarQueryParameter['contactLastName'] = contactLastName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicSaleDetailsApi - functional programming interface
 * @export
 */
export const PublicSaleDetailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicSaleDetailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} saleId 
         * @param {string} contactLastName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSaleStatus(saleId: string, contactLastName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaleDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSaleStatus(saleId, contactLastName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicSaleDetailsApi - factory interface
 * @export
 */
export const PublicSaleDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicSaleDetailsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} saleId 
         * @param {string} contactLastName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleStatus(saleId: string, contactLastName: string, options?: any): AxiosPromise<SaleDetails> {
            return localVarFp.getSaleStatus(saleId, contactLastName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicSaleDetailsApi - object-oriented interface
 * @export
 * @class PublicSaleDetailsApi
 * @extends {BaseAPI}
 */
export class PublicSaleDetailsApi extends BaseAPI {
    /**
     * 
     * @param {string} saleId 
     * @param {string} contactLastName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicSaleDetailsApi
     */
    public getSaleStatus(saleId: string, contactLastName: string, options?: AxiosRequestConfig) {
        return PublicSaleDetailsApiFp(this.configuration).getSaleStatus(saleId, contactLastName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicSaleDocumentsApi - axios parameter creator
 * @export
 */
export const PublicSaleDocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} saleId 
         * @param {string} contactLastName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocuments: async (saleId: string, contactLastName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('listDocuments', 'saleId', saleId)
            // verify required parameter 'contactLastName' is not null or undefined
            assertParamExists('listDocuments', 'contactLastName', contactLastName)
            const localVarPath = `/v1/public/sale-details/{saleId}/documents`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contactLastName !== undefined) {
                localVarQueryParameter['contactLastName'] = contactLastName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} saleId 
         * @param {string} contactLastName 
         * @param {string} filename 
         * @param {DocumentType} documentType 
         * @param {File} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument: async (saleId: string, contactLastName: string, filename: string, documentType: DocumentType, body?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('uploadDocument', 'saleId', saleId)
            // verify required parameter 'contactLastName' is not null or undefined
            assertParamExists('uploadDocument', 'contactLastName', contactLastName)
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('uploadDocument', 'filename', filename)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('uploadDocument', 'documentType', documentType)
            const localVarPath = `/v1/public/sale-details/{saleId}/documents`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contactLastName !== undefined) {
                localVarQueryParameter['contactLastName'] = contactLastName;
            }

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicSaleDocumentsApi - functional programming interface
 * @export
 */
export const PublicSaleDocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicSaleDocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} saleId 
         * @param {string} contactLastName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDocuments(saleId: string, contactLastName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Document>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDocuments(saleId, contactLastName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} saleId 
         * @param {string} contactLastName 
         * @param {string} filename 
         * @param {DocumentType} documentType 
         * @param {File} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDocument(saleId: string, contactLastName: string, filename: string, documentType: DocumentType, body?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDocument(saleId, contactLastName, filename, documentType, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicSaleDocumentsApi - factory interface
 * @export
 */
export const PublicSaleDocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicSaleDocumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} saleId 
         * @param {string} contactLastName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocuments(saleId: string, contactLastName: string, options?: any): AxiosPromise<Array<Document>> {
            return localVarFp.listDocuments(saleId, contactLastName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} saleId 
         * @param {string} contactLastName 
         * @param {string} filename 
         * @param {DocumentType} documentType 
         * @param {File} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument(saleId: string, contactLastName: string, filename: string, documentType: DocumentType, body?: File, options?: any): AxiosPromise<void> {
            return localVarFp.uploadDocument(saleId, contactLastName, filename, documentType, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicSaleDocumentsApi - object-oriented interface
 * @export
 * @class PublicSaleDocumentsApi
 * @extends {BaseAPI}
 */
export class PublicSaleDocumentsApi extends BaseAPI {
    /**
     * 
     * @param {string} saleId 
     * @param {string} contactLastName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicSaleDocumentsApi
     */
    public listDocuments(saleId: string, contactLastName: string, options?: AxiosRequestConfig) {
        return PublicSaleDocumentsApiFp(this.configuration).listDocuments(saleId, contactLastName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} saleId 
     * @param {string} contactLastName 
     * @param {string} filename 
     * @param {DocumentType} documentType 
     * @param {File} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicSaleDocumentsApi
     */
    public uploadDocument(saleId: string, contactLastName: string, filename: string, documentType: DocumentType, body?: File, options?: AxiosRequestConfig) {
        return PublicSaleDocumentsApiFp(this.configuration).uploadDocument(saleId, contactLastName, filename, documentType, body, options).then((request) => request(this.axios, this.basePath));
    }
}


