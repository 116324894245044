import {
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_CURRENT_ROW,
  SET_MODAL_WIDTH,
  RELOAD_COMPONENT,
} from "./types";
import { ModalType } from "./../models/Modal";
export interface ModalAction {
  type: string;
  payload?: ModalType;
  data?: any;
}

export const openModal = (modalType: ModalType, data?: any): ModalAction => {
  return {
    type: OPEN_MODAL,
    payload: modalType,
    data,
  };
};

export const closeModal = (): ModalAction => {
  return {
    type: CLOSE_MODAL,
  };
};

export const setCurrentRow = (row: any) => {
  return {
    type: SET_CURRENT_ROW,
    payload: row,
  };
};

export const setModalWidth = (width: number | undefined) => {
  return {
    type: SET_MODAL_WIDTH,
    payload: width,
  };
};

export const reloadComponent = () => {
  return {
    type: RELOAD_COMPONENT,
  };
};
