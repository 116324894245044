import { Col, Layout, Row, Image } from "antd";
import React, { useEffect } from "react";
import {
  Routes, Route, Navigate
} from "react-router-dom";
import SMSDetailsPage from "./components/SMSDetailsPage/SMSDetailsPage";
import SMSModal from "./components/SMSModal/SMSModal";
import SMSSaleLoginForm from "./components/SMSSaleLoginForm/SMSSaleLoginForm";
import useWindowDimensions from "./hooks/useWindowsDimensions";
import "./App.less";

const { Header } = Layout;

const App: React.FC = () => {
  const { width } = useWindowDimensions();
  const headerImageSrc =
    process.env.PUBLIC_URL + "/images/sellmyshares-logo-white.svg";

  useEffect(() => { });

  return (
    <Layout style={{ height: "100vh" }}>
      <Header
        style={{
          backgroundColor: "#09415C",
        }}
      >
        <Row style={{ maxWidth: 1240 , margin: "0px auto"}} align="middle">
          <Col
            style={{ textAlign: width < 500 ? "center" : undefined}}
            xs={24}
            
          >
            <Image
              alt="SMS Logo"
              src={headerImageSrc}
              style={{
                ...{ width: 250 },
              }}
            />
          </Col>
        </Row>
      </Header>

      <div className="main-container">
        <SMSModal />
        <Routes>
          {/* Status Page App */}
          <Route path="/sale-status" element={<SMSSaleLoginForm />} />
          <Route path="/sale-status/details" element={<SMSDetailsPage />} />
          <Route path="/*" element={<Navigate to="/sale-status" replace />} />
        </Routes>
      </div>
    </Layout>
  );
};

export default App;
