import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input } from "antd";
import "./SMSSaleLoginForm.less";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../api/api";
import { Loader } from "../Loader/Loader";
import { useQuery } from "../../hooks/useQuery";
interface FormValues {
  saleId: string;
  contactLastname: string;
}

const SMSSaleLoginForm: React.FC = () => {
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const query = useQuery();

  const onFinish = async ({ saleId, contactLastname }: FormValues) => {
    const sanitizedFormValues = {
      saleId: saleId.trim(),
      contactLastname: contactLastname.trim().toLowerCase(),
    };

    try {
      setIsLoading(true);
      await api.getSaleStatus(sanitizedFormValues.saleId, sanitizedFormValues.contactLastname);
      setIsLoading(false);
      navigate(`/sale-status/details?saleId=${saleId}&contactLastName=${contactLastname}`);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader text="Sending Sale Details..." />;
  }

  return (
    <div className="sms-login-page">
      <div className="sms-form-container">
        <div className="sms-form-wrapper">
          <h1 style={{ textAlign: "center", fontSize: 30, fontWeight: "bold" }}>Track Your Share Sale</h1>
          <p style={{ fontSize: 16, lineHeight: 1.4 }}>Please enter the Sale No. and Last Name of a person involved with the sale (primary contact, individual, executor etc.)</p>
          <Form
            name="sms_sale_login_form"
            className="sms_sale_login-form"
            onFinish={onFinish}
            initialValues={{
              saleId: query ? query.get("saleId") : "",
            }}
          >
            <Form.Item
              labelAlign="left"
              label={
                <label
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Sale No. / Sale ID
                </label>
              }
              name="saleId"
              rules={[{ required: true, message: "Please input sale ID." }]}
            >
              <Input style={{ padding: "0.8rem", borderRadius: 16 }} placeholder="e.g. n9al8Q" />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              label={<label style={{ fontSize: 16, fontWeight: "bold" }}>Last Name</label>}
              name="contactLastname"
              rules={[
                {
                  required: true,
                  message: "Please input the contact lastname.",
                },
                () => ({
                  validator(_, value) {
                    if (value && value.trim().length === 0) {
                      return Promise.reject(new Error("Please input a valid contact lastname."));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input id="lastName" style={{ padding: "0.8rem", borderRadius: 16 }} placeholder="e.g. Jackman" />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button
                block
                style={{
                  backgroundColor: "#09415C",
                  minHeight: 48,
                  borderRadius: 16,
                  fontSize: 18,
                  color: "#eeeeee",
                }}
                type="primary"
                htmlType="submit"
                shape="round"
                icon={<SearchOutlined />}
                size="large"
              >
                Track Share Sale
              </Button>
            </Form.Item>
            {!isLoading && error ? (
              error.message.match(/4[0-9][0-9]/) ? (
                <Alert
                  style={{ borderRadius: 16 }}
                  message="Oops, we couldn’t find your sale..."
                  description={
                    <span style={{ textAlign: "justify", fontSize: "0.95rem" }}>
                      Please check the Sale No. and Last Name entered above are correct. Otherwise please reach out to the Sell My Shares team by email at{" "}
                      <Link
                        to="#"
                        onClick={() => {
                          window.location.href = "mailto:shares@sellmyshares.com.au";
                        }}
                      >
                        {"shares@sellmyshares.com.au"}
                      </Link>{" "}
                      or call us on 1300 722 852.
                    </span>
                  }
                  type="error"
                />
              ) : (
                <Alert style={{ borderRadius: 16 }} message="Server Error" description="Please try again later" type="error" />
              )
            ) : null}
          </Form>
        </div>
        <img style={{ marginTop: "2rem" }} alt="SMS Logo" src={process.env.PUBLIC_URL + "/images/sellmyshares-logo.svg"} />
      </div>
    </div>
  );
};

export default SMSSaleLoginForm;
