import { App, ConfigProvider } from "antd";
import React, { ReactNode } from "react";
interface AntConfigProviderProps {
  children: ReactNode;
}

const AntConfigProvider: React.FC<AntConfigProviderProps> = ({ children }) => {
  return (
    <ConfigProvider theme={{
      token: {
        colorPrimary: "#1890FF",
        colorBgLayout: "#f0f2f5"
      },
    }}>
      <App>
        {children}
      </App>
    </ConfigProvider>
  );
};

export default AntConfigProvider;
